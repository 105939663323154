import React, { useState, createContext, useEffect } from 'react';
import { useFetchGenerations } from '../Hooks/useFetchGenerations';

export const GenerationContext = createContext();

export const GenerationContextProvider = (props) => {
  const {
    generations: generationsData,
    loading,
    error,
  } = useFetchGenerations();

  const [generations, setGenerations] = useState(generationsData);
  useEffect(() => {
    setGenerations(generationsData);
  }, [generationsData]);

  if (loading) return null;
  if (error) console.error(error);

  return (
    <GenerationContext.Provider value={[generations, setGenerations]}>
      {props.children}
    </GenerationContext.Provider>
  );
};
