import { useEffect, useReducer, useState } from "react";
// import { fetchCollection } from "../Utils/utils";
import { DB_COLLECTION, NFT_QUERY_LIMIT, genList } from "../Utils/constants";
import { GraphQLClient } from "graphql-request";
import BN from "bn.js";
// Constants
export const NFTS_GET = "genNfts";
export const NFTS_GET_MORE = "seeMoreNfts";
export const NFTS_EMPTY = "emptyNfts";
export const NFTS_FAIL = "nftsFail";
export const NFTS_LOADING = "nftsLoading";
const test = "";
const reducer = (state, { type, payload }) => {
  switch (type) {
    case NFTS_LOADING:
      return { ...state, loading: true };

    case NFTS_GET:
      return { count: payload.nfts.length, nfts: payload.nfts, loading: false };

    case NFTS_GET_MORE:
      const newNfts = [...state.nfts, ...payload.nfts];
      const newNftsUnique = [
        ...new Map(newNfts.map((item) => [item["tokenId"], item])).values(),
      ];
      return {
        count: payload.nfts.length,
        nfts: newNftsUnique,
        loading: false,
      };

    case NFTS_EMPTY:
      return { ...state, nfts: [] };

    case NFTS_FAIL:
      return { loading: false, error: payload.error };

    default:
      return state;
  }
};

/**
 *
 * @param {int} genId
 * @param {str} flow
 * @param {arr=[lowerValue:int, higherValue:int]} priceRange
 * @param {str} sortBy
 * @param {null or int} seeMore
 * @returns array of nfts
 */

export const useFetchNfts = (
  genId,
  generationChanged,
  flow,
  priceRange,
  sortBy,
  sortChanged,
  seeMore,
  curOffset
) => {
  const [state, dispatch] = useReducer(reducer, {
    nfts: [],
    loading: false,
    error: null,
    count: 0,
  });

  //   const [nftOffset, setNFTOffset] = useState(0);
  let nftOffset = curOffset;
  let curLimit = NFT_QUERY_LIMIT;

  useEffect(() => {
    const canAccessAllGens =
      (flow === "inventory" && genId === "all") ||
      (flow === "marketplace" && genId === "all");

    const whereOptions = canAccessAllGens
      ? [
          // Fetch all NFTs
        ]
      : [
          // Fetch specific NFTs by genId
          {
            fieldPath: "generationId",
            operatorString: "==",
            value: genId,
          },
        ];

    // if (flow === "marketplace") {
    //   // Fetch only NFTs with isForSale: true
    //   whereOptions.push({
    //     fieldPath: "isForSale",
    //     operatorString: "==",
    //     value: true,
    //   });
    // }
    if (flow === "inventory") {
      // Fetch only NFTs of logged in user
      whereOptions.push({
        fieldPath: "seller",
        operatorString: "==",
        value: `"${window?.ethereum?.selectedAddress}"` || "",
      });
    }
    if (priceRange[0] !== null && priceRange[1] !== null) {
      // Fetch NFTS by given priceRange range
      console.log("priceRange: ", priceRange);
      whereOptions.push(
        {
          fieldPath: "price",
          operatorString: ">=",
          value: `"${new BN(priceRange[0] + "")
            .mul(new BN(Math.pow(10, 18) + ""))
            .toString()}"`,
        },
        {
          fieldPath: "price",
          operatorString: "<=",
          //   value: `"${new BN(priceRange[1] + "")}"`,

          value: `"${new BN(priceRange[1] + "")
            .mul(new BN(Math.pow(10, 18) + ""))
            .toString()}"`,
        }
      );
      console.log(whereOptions);
    }

    // Sort NFTs
    const sortOptions = [];
    if (priceRange[0] !== null && priceRange[1] !== null) {
      sortOptions.push({
        sortOption: "price",
        sortingType: "asc",
      });
    }
    if (sortBy === "gen-high") {
      sortOptions.push({ sortOption: "generationId", sortingType: "desc" });
    }
    if (sortBy === "gen-low") {
      sortOptions.push({ sortOption: "generationId", sortingType: "asc" });
    }
    if (sortBy === "price-low") {
      if (priceRange[0] === 0 && priceRange[1] === null) {
        sortOptions.push({
          sortOption: "price",
          sortingType: "asc",
        });
      }
    }
    if (sortBy === "price-high") {
      if (priceRange[0] !== null && priceRange[1] !== null) {
        sortOptions[0].sortingType = "desc";
      } else {
        sortOptions.push({
          sortOption: "price",
          sortingType: "desc",
        });
      }
    }
    // if (sortBy === "all") {
    //   sortOptions.push({
    //     sortOption: "tokenId",
    //     sortingType: "asc",
    //   });
    // }
    if (sortBy === "token-low") {
      sortOptions.push({ sortOption: "tokenId", sortingType: "asc" });
    }
    if (sortBy === "token-high") {
      sortOptions.push({ sortOption: "tokenId", sortingType: "desc" });
    }

    // Empty NFTs state if sortChanged is true
    if (sortChanged || generationChanged) {
      window.scrollTo(0, 0);
      dispatch({
        type: NFTS_EMPTY,
        payload: [],
      });
    }

    const fetchNftList = async (curLimit, offset) => {
      const graphQLClient = new GraphQLClient(
        "https://api.studio.thegraph.com/query/64612/genmarket/version/latest"
      );

      let conditionString = `skip: ${offset}`;
      if (curLimit) {
        conditionString += `, first: ${curLimit}`;
      }

      //   let conditionString = `first: ${curLimit}, skip: ${offset}`;

      if (whereOptions.length > 0) {
        conditionString += `,where: {`;
      }

      for (let i = 0; i < whereOptions.length; i++) {
        const whereObj = whereOptions[i];
        if (whereObj.operatorString === "==") {
          conditionString += `${whereObj.fieldPath}: ${whereObj.value}`;
        }

        if (whereObj.operatorString === ">=") {
          conditionString += `${whereObj.fieldPath}_gte: ${whereObj.value}`;
        }
        if (whereObj.operatorString === "<=") {
          conditionString += `${whereObj.fieldPath}_lte: ${whereObj.value}`;
        }

        if (i < whereOptions.length - 1) {
          conditionString += ",";
        }
      }

      if (whereOptions.length > 0) conditionString += "}";

      if (sortOptions.length > 0) {
        const sortObj = sortOptions[0];
        conditionString += `,orderBy: ${sortObj.sortOption}, orderDirection: ${sortObj.sortingType}`;
      }

      //   for(let i = 0; i < sortOptions.length; i++)
      //   {
      //     const sortObj = sortOptions[i];
      //     fi(sortObj.operatorString === '==')
      //     {

      //     }
      //   }

      //   {
      //     fieldPath: "genId",
      //     operatorString: "==",
      //     value: genId,
      //   }
      //   '      {\n' +
      //   '          stackListings(skip: 100, first: 10,orderBy: price, orderDirection: asc) {\n' +
      //   '            seller,\n' +
      //   '            generationId,\n' +
      //   '            tokenId\n' +
      //   '            price\n' +
      //   '          }\n' +
      //   '        }'

      const query = `
      {
          stackListings(${conditionString}) {
            seller,
            generationId,
            tokenId
            price
          }
        }`;

      console.log("queryString: ", query);
      try {
        const fetchedData = await graphQLClient.request(query);

        const stackListings = fetchedData.stackListings;

        console.log("fetchedData: ", fetchedData, stackListings);

        const processedData = stackListings.map((nftObj) => ({
          id: `${genList[nftObj.generationId].genAddress}_${
            nftObj.generationId
          }_${nftObj.tokenId}`,
          imageURL: "",
          tokenId: nftObj.tokenId,
          price: new BN("" + nftObj.price),
          // .toNumber(),
          genId: nftObj.generationId,
        }));

        console.log("processedDat: ", processedData);
        return {
          data: processedData,
        };
      } catch (error) {
        console.log("Failed to fetch GraphQL data:", error);
        return {
          data: null,
        };
      }
    };

    const fetchOwnedNFTList = async (offset) => {
      const graphQLClient = new GraphQLClient(
        "https://api.studio.thegraph.com/query/64612/stack_gen0/version/latest"
      );

      const query = `
        {
            tokens(where: {owner: "${window.ethereum.selectedAddress}"}) {
                id
                tokenAddress
                tokenID
                contentURI
            }
          }`;
      console.log("query: ", query);
      try {
        const fetchedData = await graphQLClient.request(query);

        const stackListings = fetchedData.tokens;

        let marketPlaceMap = {};
        let marketPlacePriceMap = {};
        const marketPlaceListResp = await fetchNftList(undefined, 0);
        if (marketPlaceListResp.data) {
          const marketPlaceList = marketPlaceListResp.data;

          for (let i = 0; i < marketPlaceList.length; i++) {
            const marketObj = marketPlaceList[i];
            marketPlaceMap[marketObj.tokenId] = true;
            marketPlacePriceMap[marketObj.tokenId] = marketPlaceList[i].price;
            // if(marketObj)
          }
        }

        const data = stackListings.map((nftObj) => {
          const genObj = genList.find(
            (genObj) =>
              genObj.genAddress.toLowerCase() ===
              nftObj.tokenAddress.toLowerCase()
          );

          console.log(
            `${genList[genObj.genId].genAddress}_${genObj.genId}_${
              nftObj.tokenID
            }`
          );
          return {
            id: `${genList[genObj.genId].genAddress}_${genObj.genId}_${
              nftObj.tokenID
            }`,
            imageURL: "",
            tokenId: nftObj.tokenID,
            tokenAddress: nftObj.tokenAddress,
            price: marketPlacePriceMap[nftObj.tokenID] || new BN("0"),
            genId: genObj.genId,
            isForSale: false,
          };

          // genId: genObj?.genId,
        });

        console.log("stackListings: ", data);
        return {
          data,
        };
      } catch (error) {
        console.error("Failed to fetch GraphQL data:", error.message);
        return {
          data: null,
        };
      }
    };

    const fetch = async () => {
      try {
        dispatch({ type: NFTS_LOADING });
        // const querySnap = await fetchCollection(
        //   DB_COLLECTION,
        //   whereOptions,
        //   sortOptions,
        //   seeMore
        // );

        console.log("fetching: ", nftOffset, seeMore);
        const resp = await fetchNftList(NFT_QUERY_LIMIT, nftOffset);
        console.log("resp: ", resp);

        if (!resp.data) throw new Error("failed to fetch nft list");
        let nfts = resp.data;
        // querySnap?.forEach((doc) => {
        //   return nfts.push({
        //     id: doc.id,
        //     ...doc.data(),
        //   });
        // });
        // setNFTOffset(nftOffset + NFT_QUERY_LIMIT);
        if (seeMore) {
          console.log("calling see more");
          dispatch({
            type: NFTS_GET_MORE,
            payload: { nfts },
          });
        } else {
          console.log("get nfts");
          dispatch({
            type: NFTS_GET,
            payload: { nfts },
          });
        }
      } catch (error) {
        console.log(error.message);
        dispatch({ type: NFTS_FAIL, payload: { error: error.message } });
      }
    };

    const fetchOwned = async () => {
      try {
        dispatch({ type: NFTS_LOADING });
        // const querySnap = await fetchCollection(
        //   DB_COLLECTION,
        //   whereOptions,
        //   sortOptions,
        //   seeMore
        // );

        // console.log("fetching: ", nftOffset, seeMore);
        const resp = await fetchOwnedNFTList(nftOffset);
        console.log("resp: ", resp);

        if (!resp.data) throw new Error("failed to fetch nft list");
        let nfts = resp.data;
        // querySnap?.forEach((doc) => {
        //   return nfts.push({
        //     id: doc.id,
        //     ...doc.data(),
        //   });
        // });
        // setNFTOffset(nftOffset + NFT_QUERY_LIMIT);
        if (seeMore) {
          console.log("calling see more");
          dispatch({
            type: NFTS_GET_MORE,
            payload: { nfts },
          });
        } else {
          console.log("get nfts");
          dispatch({
            type: NFTS_GET,
            payload: { nfts },
          });
        }
      } catch (error) {
        console.log(error.message);
        dispatch({ type: NFTS_FAIL, payload: { error: error.message } });
      }
    };

    console.log("flow: ", flow);
    if (flow === "marketplace" || flow === "nodes" || flow === "genesis") {
      fetch();
    }
    if (flow === "inventory") {
      fetchOwned();
    }
  }, [
    genId,
    flow,
    priceRange,
    seeMore,
    sortBy,
    window?.ethereum?.selectedAddress,
  ]);
  return { state };
};
