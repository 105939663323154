import { useContext } from "react";
import { NftFetchingHelperContext } from "../../Context/NftFetchingHelperContext";
import { Select } from "antd";
import { NFT_QUERY_LIMIT } from "../../Utils/constants";
const { Option } = Select;

const SortOptions = () => {
  const [nftFetchingHelper, setNftFetchingHelper] = useContext(
    NftFetchingHelperContext
  );

  return (
    <div>
      <Select
        size="large"
        onChange={(value) =>
          setNftFetchingHelper({
            ...nftFetchingHelper,
            generationChanged: false,
            sortBy: value,
            sortChanged: true,
            seeMore: false,
            curOffset: 0,
            nextOffset: NFT_QUERY_LIMIT,
          })
        }
        className="sort-list-marketplace"
        value={nftFetchingHelper.sortBy}
      >
        <Option value="all">All</Option>
        <Option value="price-low">Price (low-high)</Option>
        <Option value="price-high">Price (high-low)</Option>
        {nftFetchingHelper.generation !== "all" &&
          (nftFetchingHelper.priceRange[0] === null ||
            nftFetchingHelper.priceRange[1] === null) && (
            <>
              <Option value="token-low">Token Id (low-high)</Option>
              <Option value="token-high">Token Id (high-low)</Option>
            </>
          )}
        {nftFetchingHelper.generation === "all" &&
          (nftFetchingHelper.priceRange[0] === null ||
            nftFetchingHelper.priceRange[1] === null) && (
            <>
              <Option value="gen-low">Generation (low-high)</Option>
              <Option value="gen-high">Generation (high-low)</Option>
            </>
          )}
      </Select>
    </div>
  );
};

export default SortOptions;
