import injected from "./injected";
import boltx from "./boltx";
import walletconnect from "./walletconnect";
import portis from "./portis";
import fortmatic from "./fortmatic";
import torus from "./torus";
import venly from "./venly";
import authereum from "./authereum";
import burnerconnect from "./burnerconnect";
import mewconnect from "./mewconnect";
import dcentwallet from "./dcentwallet";
import bitski from "./bitski";
import frame from "./frame";
import binancechainwallet from "./binancechainwallet";
import coinbasewallet, { walletlink } from "./coinbasewallet";
import sequence from "./sequence";
import clvwallet from "./clvwallet";
import opera from "./opera";
import web3auth from "./web3auth";
import bitkeep from "./bitkeep";
import starzwallet from "./starzwallet";
import ledger from "./ledger";
import phantom from "./phantom";

export {
  injected,
  walletconnect,
  boltx,
  portis,
  torus,
  fortmatic,
  venly,
  authereum,
  burnerconnect,
  mewconnect,
  dcentwallet,
  bitski,
  frame,
  binancechainwallet,
  coinbasewallet,
  walletlink,
  sequence,
  clvwallet,
  opera,
  bitkeep,
  starzwallet,
  web3auth,
  ledger,
  phantom
};
