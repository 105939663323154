// Components
import NFTs from "../Components/NFTs/NFTs";

const MarketPlace = (props) => {
  const { path } = props.match.params || {};

  return <NFTs path={path} flow="marketplace" />;
};

export default MarketPlace;
