import { createContext, useState } from "react";
import { NFT_QUERY_LIMIT } from "../Utils/constants";

export const NftFetchingHelperContext = createContext();

export const NftFetchingHelperContextProvider = (props) => {
  const pathArr = window.location.href.split("/");
  const path = pathArr[pathArr.length - 1];

  const [nftFetchingHelper, setNftFetchingHelper] = useState({
    generation: path === "genesis" ? 0 : "all",
    generationChanged: false,
    sortBy: "all",
    sortChanged: false,
    seeMore: 0,
    priceRange: [0, null],
    curOffset: 0,
    nextOffset: NFT_QUERY_LIMIT,
  });
  return (
    <NftFetchingHelperContext.Provider
      value={[nftFetchingHelper, setNftFetchingHelper]}
    >
      {props.children}
    </NftFetchingHelperContext.Provider>
  );
};
