export const QUERY_LIMIT = 10;

export const NFT_DATA_ERROR_MSG = "";
export const NFT_TRANSACTION_SUCCESS_MSG = "Transaction was successful";
export const NFT_TRANSACTION_ERROR_MSG = "Oops! Something went wrong";
export const NFT_REWARDS_ERROR_MSG = "Oops! Something went wrong";
export const NFT_ROYALTY_ERROR_MSG = "Oops! Something went wrong";
export const NFT_PAY_DATE_ERROR_MSG = "Oops! Something went wrong";
export const NFT_DRIP_RATE_ERROR_MSG = "Oops! Something went wrong";
export const NFT_ATTRIBUTES_ERROR_MSG = "Oops! Something went wrong";
export const GEN0_VALUE = "200000000000000000000";
export const GEN1_PLUS_VALUE = "200000000000000000000";
export const gen0SubscriptionNftAddress =
  "0x58e49a747afcf7fb6d551aab06ef592485e3e01d";
export const gen1PlusSubscriptionNftAddress =
  "0xd9a26c042b51ec5d54222e17629e4c4b4be6a8dd";
export const marketAddress = "0xee307a9616905e6168a541A0617ec4AE0e8ad697";
export const royaltyAddress = "0x5b9E5461313881518B62800d58D59C4f3B3d0ce7";
export const stackTokenAddress = "0x980111ae1b84e50222c8843e3a7a038f36fecd2b";
export const genesisNFT = "0x80a44A37B9198F5fa5C2F4EF2748cF109A288174";
export const MoralisAppId = "8RIlQgep1V8Z4K4QGRB8Zpwn2ocvKDXigRYfIcng";
export const MoralisServerLink =
  "https://upnhjiwrcp2x.usemoralis.com:2053/server";
export const NFT_QUERY_LIMIT = 10;

export const genList = [
  {
    genAddress: "0x80a44A37B9198F5fa5C2F4EF2748cF109A288174",
    genId: 0,
  },
  {
    genAddress: "0xBF55265e7C2Cb77D64c791430C759069620D5A21",
    genId: 1,
  },
  {
    genAddress: "0xb65E58D28f2C2bC8ffe6DF20141301C8c87e792C",
    genId: 2,
  },
  {
    genAddress: "0x244cB430d58A197BE31919336406695bAb58d9F3",
    genId: 3,
  },
  {
    genAddress: "0x3C7196C7BE3b54c4644e8D6562EcbD4F5C0Fd7EC",
    genId: 4,
  },
  {
    genAddress: "0x48199892Ee19A045B2310C0AB39c39de55BF76FD",
    genId: 5,
  },
  {
    genAddress: "0xcc1879ebd66bD2a2937a1602F29645843d5d66DE",
    genId: 6,
  },
  {
    genAddress: "0x8FbeeE065D7F626F771a7dd2120CF831639E469f",
    genId: 7,
  },
  {
    genAddress: "0x62615ace553F6bB73fa6336cFEDc0B57C49D5642",
    genId: 8,
  },
  {
    genAddress: "0x55bfB8de8820762Ad9911467edA615a89f561860",
    genId: 9,
  },
  {
    genAddress: "0x514c90500b4e87Ce77524EF161d442Eb937EA9Ef",
    genId: 10,
  },
];
export const gasPrice = "1000000000";
export const ethValue = "5000000000000000000";

// 2, 978, 250;

export const GAS_HIKE = 1.2;
