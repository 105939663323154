import './footer.scss'
import logo from "../../Assets/logo.svg";
import decloudlogo from "../../Assets/DECLOUD_FOUNDATION.svg"

export const Footer = () => {

    return(
        <>
            <div className="footer">
                <h6>© {new Date().getFullYear()} <img src={decloudlogo} alt='decloud function'/></h6>
                <div className='powered_section'>
                    <h6>Powered by: </h6>                    
                    <img src={logo} alt='logo'/>
                </div>
            </div>
        </>
    )

}