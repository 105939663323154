import React, { useState, createContext } from "react";

export const WalletAddressContext = createContext();

export const WalletAddressContextProvider = (props) => {
  const [walletAddress, setWalletAddress] = useState(null);

  return (
    <WalletAddressContext.Provider value={[walletAddress, setWalletAddress]}>
      {props.children}
    </WalletAddressContext.Provider>
  );
};
