import { useState, useContext, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import Web3Modal from "web3modal";
import { Menu, Button } from "antd";
// Components
import PopupComponent from "../Popup/PopupComponent";
import ButtonComponent from "../Buttons/ButtonComponent";
// Contexts
import { Web3Context } from "../../Context/Web3Context";
import { WalletAddressContext } from "../../Context/WalletAddressContext";
// Utils
import ellipseAddress from "../../Utils/utils";
// Web 3 Functions
import Web3 from "web3";
// Assets
import logo from "../../Assets/logo.png";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
// css
import "./newHeader.scss";

const Header = (props) => {
  const history = useHistory();

  const [showPopup, setShowPopup] = useState(false);
  const [popupObj] = useState({
    title: "Success",
    message: "",
  });

  const [, setWeb3Instance] = useContext(Web3Context);
  const [walletAddress, setWalletAddress] = useContext(WalletAddressContext);
  const [collapsed, setCollapsed] = useState(false);

  const connectWallet = async () => {
    let _web3 = null;
    let _accounts = null;
    let _provider = null;

    _provider = await web3Modal.connect();
    _web3 = new Web3(_provider);
    setWeb3Instance(_web3);
    _accounts = await _web3.eth.getAccounts();
    const _chainId = await _web3.eth.getChainId();

    if (Number(_chainId) === 137) {
      setWalletAddress(_accounts[0].toLowerCase());
    } else {
      setWalletAddress(null);
    }

    _provider.on("accountsChanged", (accounts) => {
      props.history.push("/");
      connectWallet();
    });

    _provider.on("chainChanged", (chainId) => {
      connectWallet();
    });

    _provider.on("connect", (info) => {
      connectWallet();
    });

    _provider.on("disconnect", (error) => {
      connectWallet();
    });
  };

  const disconnectWallet = async () => {
    setWalletAddress(null);
    setWeb3Instance(null);
  };

  const providerOptions = {
    /* See Provider Options Section */
  };

  const web3Modal = new Web3Modal({
    network: "polygon", // optional
    cacheProvider: true, // optional
    providerOptions, // required
  });

  useEffect(() => {
    connectWallet();
    // eslint-disable-next-line
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <div className="header">
        <div
          className="headerLogo headerItemLeft"
          style={{ cursor: "pointer" }}
        >
          <img
            src={logo}
            alt="logo"
            onClick={() => history.push("/")}
            style={{ width: "100%", maxWidth: "200px" }}
          />
          <span>(We are in beta V1.0.101)</span>
        </div>
        <div
          className={`sidebarItem ${
            window.location.pathname === "/" ||
            window.location.pathname === "/marketplace/genesis" ||
            window.location.pathname === "/marketplace/nodes" ||
            history.location.state?.flow === "marketplace" ||
            (!history.location.state?.flow && props.match.params.id)
              ? "selected"
              : ""
          } desktop`}
          onClick={() => {
            props.history.push("/");
          }}
        >
          <i className="fa fa-university" aria-hidden="true"></i>
          <span className="sidebarItemText">Marketplace</span>
        </div>
        {walletAddress && (
          <div
            className={`sidebarItem ${
              window.location.pathname === "/inventory" ||
              history.location.state?.flow === "inventory"
                ? "selected"
                : ""
            } desktop`}
            onClick={() => {
              props.history.push("/inventory");
            }}
          >
            <i className="fa fa-university" aria-hidden="true"></i>
            <span className="sidebarItemText">Inventory</span>
          </div>
        )}

        {/* Connect Button */}
        <div className="headerItemRight desktop">
          {walletAddress ? (
            <>
              <span className="mr-3">{ellipseAddress(walletAddress)}</span>
              <ButtonComponent
                click={disconnectWallet}
                className="connectBtn"
                name="Disconnect"
              />
            </>
          ) : (
            <ButtonComponent
              click={connectWallet}
              className="connectBtn"
              name="Connect Wallet"
            />
          )}
        </div>
        <Button
          onClick={toggleCollapsed}
          className="mobile"
          style={{
            marginBottom: 16,
            backgroundColor: "#b84aff",
            color: "#ffffff",
            border: "#b84aff",
          }}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
        <PopupComponent
          show={showPopup}
          title={popupObj.title}
          message={popupObj.message}
          closePopup={() => {
            setShowPopup(false);
          }}
        />
      </div>
      {collapsed && (
        <Menu
          theme="dark"
          mode="vertical"
          defaultSelectedKeys={["item1"]}
          // overflowedIndicator={<MenuOutlined />}
          className="toggleButton-mobileView"
        >
          <Menu.Item
            key="item1"
            className={`sidebarItem ${
              window.location.pathname === "/" ||
              window.location.pathname === "/marketplace/genesis" ||
              window.location.pathname === "/marketplace/nodes" ||
              history.location.state?.flow === "marketplace" ||
              (!history.location.state?.flow && props.match.params.id)
                ? "selected"
                : ""
            } mobile`}
            onClick={() => {
              props.history.push("/");
            }}
          >
            <i className="fa fa-university" aria-hidden="true"></i>
            <span className="sidebarItemText">Marketplace</span>
          </Menu.Item>
          {walletAddress && (
            <Menu.Item
              key={"item2"}
              className={`sidebarItem ${
                window.location.pathname === "/inventory" ||
                history.location.state?.flow === "inventory"
                  ? "selected"
                  : ""
              } mobile`}
              onClick={() => {
                props.history.push("/inventory");
              }}
            >
              <i className="fa fa-university" aria-hidden="true"></i>
              <span className="sidebarItemText">Inventory</span>
            </Menu.Item>
          )}
          <Menu.Item key={"item3"} style={{ height: "65px" }}>
            {/* Connect Button */}
            <div className="headerItemRight mobile">
              {walletAddress ? (
                <>
                  <span className="mr-3">{ellipseAddress(walletAddress)}</span>
                  <ButtonComponent
                    click={disconnectWallet}
                    className="connectBtn"
                    name="Disconnect"
                  />
                </>
              ) : (
                <ButtonComponent
                  click={connectWallet}
                  className="connectBtn"
                  name="Connect Wallet"
                />
              )}
            </div>
          </Menu.Item>
        </Menu>
      )}
    </>
  );
};

export default withRouter(Header);
