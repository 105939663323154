import { useState, useRef, useEffect, useContext } from "react";
import { NftFetchingHelperContext } from "../../Context/NftFetchingHelperContext";
// Contexts
import { WalletAddressContext } from "../../Context/WalletAddressContext";
// Components
import Header from "../../Components/Header/Header";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { InfiniteList } from "../InfiniteList.js/InfiniteList";
import SortOptions from "../../Components/SortOptions/SortOptions";
// Hooks
import { useFetchGenerations } from "../../Hooks/useFetchGenerations";
import { useFetchNfts } from "../../Hooks/useFetchNfts";
// Utils
import { sortGens } from "../../Utils/utils";
import { Footer } from "../Footer/Footer";

const NFTs = ({ flow, path }) => {
  // Paths
  const isPathGenesis = path === "genesis";
  const isPathNodes = path === "nodes";
  const isPathInventory = path === "inventory";

  const [walletAddress] = useContext(WalletAddressContext);
  const [nftFetchingHelper, setNftFetchingHelper] = useContext(
    NftFetchingHelperContext
  );
  const {
    generation,
    generationChanged,
    sortBy,
    sortChanged,
    seeMore,
    priceRange,
    curOffset,
    nextOffset,
  } = nftFetchingHelper;

  const { generations, loading, error } = useFetchGenerations();

  const scrollDivRef = useRef(null);

  const [retriggerFlow, setRetriggerFlow] = useState(false);

  const { state } = useFetchNfts(
    generation,
    generationChanged,
    flow,
    priceRange,
    sortBy,
    sortChanged,
    seeMore,
    curOffset
  );

  return (
    <div className="home-container" style={{ background: "#0e1118" }}>
      <div className="wh100">
        <Header
          setRetriggerFlow={() => setRetriggerFlow(!retriggerFlow)}
          showStakingButtons={true}
        />
        <div className="pageLayout">
          {isPathInventory && !walletAddress ? (
            <div
              style={{
                width: "100%",
                height: "80vh",
                display: "grid",
                placeItems: "center",
              }}
            >
              <h5 style={{ color: "white" }}>
                Install Metamask, and connect a wallet using Polygon chain to
                access the Inventory.
              </h5>
            </div>
          ) : (
            <>
              <Sidebar
                priceRange={priceRange}
                generations={isPathGenesis ? [] : sortGens(generations)}
              />
              <div className="items-list-div">
                <div className="items-list-header">
                  <div style={{ zIndex: 60, position: "relative" }}>
                    <hr className="header-hr" />
                    <h1 className="header-text">
                      {flow === "marketplace" ? "NFTs for Sale" : "Inventory"}
                    </h1>
                  </div>
                  <div className="item-list-sort">
                    <SortOptions />
                  </div>
                </div>

                <div ref={scrollDivRef}>
                  <InfiniteList
                    flow={flow}
                    data={state?.nfts}
                    count={state?.count}
                    loading={state?.loading}
                    error={state?.error}
                    generations={generations}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default NFTs;
