import { useState } from "react";
import { withRouter } from "react-router-dom";
// Components
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
// Assets
import poly from "../../Assets/poly.png";
import logo from "../../Assets/logo.svg";
import stackVideo from "../../Assets/stackVideo.gif";
import Loader from "../Loader/loader";
// css
import "./newCard.scss";
import ellipseAddress, { convertToMatic } from "../../Utils/utils";

const Card = (props) => {
  var randomColor = Math.floor(Math.random() * 16777215).toString(16);

  const { item } = props || {};
  const [loading, setLoading] = useState(true);

  const myImage = (e) => {
    setLoading(false);
  };

  console.log("item: ", item);
  return (
    <div
      className={
        !props.match.params.id ? "card-nft" : "card-nft card-nft-single"
      }
      style={{ maxWidth: `${props.width}px` }}
      onClick={props.onClick}
    >
      <div
        style={{
          width: `${props.width}px`,
          height: `${props.width}px`,
        }}
        className="img-div"
      >
        <Loader show={loading} spinner />
        <img
          alt="nft"
          className="nft-img image-radius"
          src={item?.imageURL ? `https://${item?.imageURL}` : props.src}
          onError={(e) => {
            e.target.src = stackVideo;
          }}
          onLoad={(e) => {
            myImage(e);
          }}
        />
        {props.subTitle ? (
          <div className="middle" style={{ background: `#${randomColor}1a` }}>
            <div>{props.subTitle}</div>
          </div>
        ) : null}
      </div>
      {props.nftItem ? (
        <>
          <div className="items-list-header">
            <div>
              <div>
                {console.log("tokenId item: ", item)}
                <span> Token Id: </span> {item.tokenId}
              </div>
            </div>
            <div className="prices">
              <div className="matic-price">
                <img className="price-logo" src={poly} alt="logo" />
                {item && Object.keys(item).length > 0
                  ? convertToMatic(item.price)
                  : "-"}
              </div>
              {/* <div className="dollor-price">
                <span>$ </span>
                {(Number(item.price) * props.maticPrice).toFixed(2) ?? 0}
              </div> */}
            </div>
          </div>
        </>
      ) : (
        <>
          {!props.allowCopy ? (
            <div className="category-header">{props.title}</div>
          ) : (
            <CopyToClipboard
              text={window.location.href}
              onCopy={() => alert("Copied")}
            >
              <div className="category-header">
                {props.title} <CopyOutlined style={{ marginLeft: 12 }} />
              </div>
            </CopyToClipboard>
          )}
        </>
      )}
      {props.showBuyNow && props.flow !== "inventory" ? (
        <>
          <div className="pl-12 itemImage" style={{ background: "#1C1F27" }}>
            <img src={logo} alt="logo" height={"16"} />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default withRouter(Card);
