import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// import { useMoralis } from "react-moralis";
// Components
import Header from "../Components/Header/Header";
import Card from "../Components/Card/Card";
import Loader from "../Components/Loader/loader";
import { toast } from "react-toastify";
import { LeftOutlined } from "@ant-design/icons";
// Contexts
import { Web3Context } from "../Context/Web3Context";
import { GenerationContext } from "../Context/GenerationContext";
// Utils
import ellipseAddress, {
  formatDate,
  getAttributes,
  getBalance,
  getMaticTokenPrice,
  getStackTokenPrice,
  checkSubscriptionExpired,
  convertToMatic,
} from "../Utils/utils";
import {
  NFT_DATA_ERROR_MSG,
  NFT_TRANSACTION_SUCCESS_MSG,
  NFT_TRANSACTION_ERROR_MSG,
  NFT_REWARDS_ERROR_MSG,
  NFT_ROYALTY_ERROR_MSG,
  NFT_PAY_DATE_ERROR_MSG,
  NFT_DRIP_RATE_ERROR_MSG,
  NFT_ATTRIBUTES_ERROR_MSG,
  GEN0_VALUE,
  GEN1_PLUS_VALUE,
} from "../Utils/constants";
// Web 3 Functions
import {
  buyStack,
  deListStackNFT,
  listStackNFT,
} from "../Utils/SmartContractUtils/MarketContractFunctions";
import {
  deposits,
  getMonthlyDripRateBonus,
  getPendingBonus,
  getPendingRewards,
} from "../Utils/SmartContractUtils/subscriptionContractFunctions";
import { pendingRoyalty } from "../Utils/SmartContractUtils/royaltyContractFunctions";
// Assets
import poly from "../Assets/poly.png";
import useFetchSingleNft from "../Hooks/useFetchSingleNft";
import ButtonComponent from "../Components/Buttons/ButtonComponent";
import { Footer } from "../Components/Footer/Footer";
import BN from "bn.js";

const SingleNFT = (props) => {
  const history = useHistory();

  const idFromUrl = props.match.params.id;
  // Fetch nft from firestoreg

  console.log("history: ", history.location.state?.flow);
  const {
    data: item,
    singleNFTFetchStatus,
    error,
  } = useFetchSingleNft(idFromUrl, history.location.state?.flow);

  const [web3Instance] = useContext(Web3Context);
  const [generations] = useContext(GenerationContext);

  const [retriggerFlow, setRetriggerFlow] = useState(false);
  const [userBalance, setUserBalance] = useState({});
  //   const [attributes, setAttributes] = useState([]);
  //   const [attributesLoading, setAttributesLoading] = useState(true);
  //   const [monthlyRateAmount, setMonthlyRateAmount] = useState(null);
  const [listingPrice, setListingPrice] = useState(null);
  //   const [nextPayDate, setNextPayDate] = useState(null);
  //   const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false);
  //   const [taxRate, setTaxRate] = useState(null);
  //   const [totalSubBalance, setTotalSubBalance] = useState(null);
  //   const [stackBonusLocked, setStackBonusLocked] = useState(null);
  //   const [stackBonusUnlocked, setStackBonusUnlocked] = useState(null);
  //   const [royaltyClaimable, setRoyaltyClaimable] = useState(null);
  //   const [rewardsClaimable, setRewardsClaimable] = useState(null);
  //   const [avgTokensDrip, setAvgTokensDrip] = useState(null);

  const [webFnLoading, setWebFnLoading] = useState(false);
  const [maticPrice, setMaticPrice] = useState(null);
  const [stackPrice, setStackPrice] = useState(null);
  const [totalNftValue, setTotalNftValue] = useState(0);
  //   const { Moralis } = useMoralis();

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         // Avg Tokens Drip Per Month
  //         try {
  //           const res = await getMonthlyDripRateBonus(
  //             web3Instance,
  //             item?.genId,
  //             item?.tokenId,
  //             12
  //           );

  //           let total = res?.reduce(
  //             (previousValue, currentValue) =>
  //               Number(previousValue) + Number(currentValue),
  //             0
  //           );
  //           total = Number(total) ? total / 10 ** 18 : 0;
  //           setAvgTokensDrip(Number(total) ? total / 12 : 0);

  //           // Monthly Drip Rate Amount
  //           if (res) {
  //             let amount = "";
  //             res?.forEach((elm, index) => {
  //               let date = new Date(
  //                 new Date().setMonth(new Date().getMonth() + index)
  //               );
  //               amount += `${date.toLocaleString("default", {
  //                 month: "short",
  //               })}'${date.toLocaleString("default", {
  //                 year: "2-digit",
  //               })}: ${(Number(elm) / 10 ** 18).toFixed(2)} ${
  //                 index !== res.length - 1 ? "|" : ""
  //               } `;
  //             });
  //             setMonthlyRateAmount(amount);
  //           }
  //         } catch (error) {
  //           toast.warning(NFT_DRIP_RATE_ERROR_MSG);
  //           console.log(error.message);
  //         }

  //         // Last Pay Date
  //         try {
  //           const res = await deposits(web3Instance, item?.genId, item?.tokenId);
  //           if (res.nextPayDate !== "0") {
  //             setNextPayDate(Number(res.nextPayDate));

  //             setIsSubscriptionExpired(
  //               checkSubscriptionExpired(Number(res.nextPayDate))
  //             );
  //           }
  //           res.tax &&
  //             !isNaN(Number(res.tax)) &&
  //             setTaxRate(Number(res.tax) / 100);
  //           res.balance !== "" &&
  //             setTotalSubBalance(Number(res.balance) / 10 ** 18);
  //         } catch (error) {
  //           toast.warning(NFT_PAY_DATE_ERROR_MSG);
  //           console.log(error.message);
  //         }

  //         // Stack Bonus Locked & Unlocked
  //         let stackBonusLocked = 0;
  //         let stackBonusUnlocked = 0;
  //         try {
  //           const res = await getPendingBonus(
  //             web3Instance,
  //             item?.genId,
  //             item?.tokenId
  //           );
  //           if (res) {
  //             stackBonusLocked = res.locked;
  //             stackBonusUnlocked = res.unlocked;
  //             // stackBonusLocked = Number(res.locked);
  //             // stackBonusUnlocked = Number(res.unlocked);
  //             // Number(res.locked) !== 0 &&
  //             //   setStackBonusLocked(Number(res.locked) / 10 ** 18);
  //             // Number(res.unlocked) !== 0 &&
  //             //   setStackBonusUnlocked(Number(res.unlocked) / 10 ** 18);
  //           }
  //         } catch (error) {
  //           toast.warning(NFT_DATA_ERROR_MSG);
  //           console.log(error.message);
  //         }

  //         let royaltyClaimable = 0;
  //         // Royalty Claimable
  //         try {
  //           const res = await pendingRoyalty(
  //             web3Instance,
  //             item?.genId,
  //             item?.tokenId
  //           );

  //           console.log("pending royalty: ", res);
  //           if (res) {
  //             //   royaltyClaimable = Number(res) / 10 ** 18;
  //             royaltyClaimable = res;

  //             setRoyaltyClaimable(royaltyClaimable);
  //             //   Number(res) !== 0 && setRoyaltyClaimable(Number(res) / 10 ** 18);
  //           }
  //         } catch (error) {
  //           toast.warning(NFT_ROYALTY_ERROR_MSG);
  //           console.log(error.message);
  //         }

  //         // Rewards Claimable
  //         let rewardsClaimable = 0;
  //         try {
  //           const res = await getPendingRewards(
  //             web3Instance,
  //             item?.genId,
  //             item?.tokenId
  //           );

  //           if (res) {
  //             //   Number(res) !== 0 && setRewardsClaimable(Number(res) / 10 ** 18);
  //             //   rewardsClaimable = Number(res) / 10 ** 18;
  //             rewardsClaimable = res;
  //             setRewardsClaimable(rewardsClaimable);
  //           }
  //         } catch (error) {
  //           toast.warning(NFT_REWARDS_ERROR_MSG);
  //           console.log(error.message, item);
  //         }

  //         const maticToUSD = await getMaticTokenPrice(
  //           Moralis
  //           //   web3Instance,
  //           //   royaltyClaimable || "0"
  //         );

  //         console.log(
  //           "stackTokenVal: ",
  //           maticToUSD,
  //           royaltyClaimable,
  //           rewardsClaimable,
  //           stackBonusUnlocked,
  //           stackBonusLocked,
  //           royaltyClaimable
  //         );

  //         let stackTokenVal = new BN(rewardsClaimable);
  //         stackTokenVal = stackTokenVal
  //           .add(new BN(stackBonusUnlocked))
  //           .add(new BN(stackBonusLocked));

  //         const stackToUSD = await getStackTokenPrice(
  //           Moralis
  //           //   web3Instance,
  //           //   stackTokenVal.toString()
  //         );

  //         console.log("stackTokenVal: ", stackTokenVal.toString(), stackToUSD);

  //         let totalValue = 0;
  //         if (Number(item?.genId) === 0) {
  //           totalValue = new BN(GEN0_VALUE);

  //           totalValue = totalValue
  //             .add(new BN(maticToUSD))
  //             .add(new BN(stackToUSD));

  //           totalValue = totalValue.toString();

  //           setTotalNftValue(totalValue);
  //         } else {
  //           totalValue = new BN(GEN1_PLUS_VALUE);
  //           totalValue = totalValue
  //             .add(new BN(maticToUSD))
  //             .add(new BN(stackToUSD));

  //           totalValue = totalValue.toString();

  //           setTotalNftValue(totalValue);
  //         }
  //       } catch (err) {
  //         console.log("error in fetch data: ", err);
  //       }
  //       // Get Matic Price
  //       //   try {
  //       //     const maticPrice = await getMaticTokenPrice(web3Instance);
  //       //     // const maticPrice = "1";
  //       //     console.log("atic price: ", maticPrice);
  //       //     setMaticPrice(maticPrice);
  //       //     setWebFnLoading(false);
  //       //   } catch (err) {
  //       //     setWebFnLoading(false);
  //       //     console.log("error", err);
  //       //   }

  //       //   // Get Stack Price
  //       //   try {
  //       //     const stackPrice = await getStackTokenPrice(web3Instance);
  //       //     // const stackPrice = "1";
  //       //     setStackPrice(stackPrice);
  //       //     setWebFnLoading(false);
  //       //   } catch (err) {
  //       //     setWebFnLoading(false);
  //       //     console.log("error", err);
  //       //   }

  //       // Get Attributes
  //       //   try {
  //       //     console.log("checking getAttributes: ", item.genId, item.tokenId);
  //       //     const res = await getAttributes(item?.genId, item?.tokenId);
  //       //     console.log("checking getAttrbutes res: ", res);
  //       //     res && setAttributes(res);
  //       //     setAttributesLoading(false);
  //       //   } catch (error) {
  //       //     setAttributesLoading(false);
  //       //     toast.warning(NFT_ATTRIBUTES_ERROR_MSG);
  //       //     console.log(error.message);
  //       //   }
  //     };

  //     if (singleNFTFetchStatus !== "SUCCESS") return;

  //     Object.keys(item).length && fetchData();
  //   }, [singleNFTFetchStatus, item]);

  // Total NFT Value Calculation
  //   useEffect(() => {
  //     console.log("singleNFTFetchStatus ", singleNFTFetchStatus);
  //     if (singleNFTFetchStatus !== "SUCCESS") return;

  //     (async () => {

  //     })();
  //   }, [
  //     singleNFTFetchStatus,
  //     rewardsClaimable,
  //     stackBonusUnlocked,
  //     royaltyClaimable,
  //     stackBonusLocked,
  //     stackPrice,
  //   ]);

  // Buy Now Button
  const handleBuyNow = async () => {
    setWebFnLoading(true);
    try {
      const res = await buyStack(
        web3Instance,
        item?.genId,
        item?.tokenId,
        item?.price
      );
      setWebFnLoading(false);
      if (res) {
        history.push({
          pathname: `/marketplace/nodes`,
        });

        // window?.location?.href = "/marketplace/nodes"
        // history
        toast.success(NFT_TRANSACTION_SUCCESS_MSG);
      } else {
        toast.error(NFT_TRANSACTION_ERROR_MSG);
      }
    } catch (error) {
      toast.warning(NFT_TRANSACTION_ERROR_MSG);
      setWebFnLoading(false);
      console.log(error.message);
    }
  };

  // Delist Button
  const handleDelist = async () => {
    setWebFnLoading(true);
    try {
      const res = await deListStackNFT(
        web3Instance,
        item?.genId,
        item?.tokenId
      );
      setWebFnLoading(false);
      if (res) {
        history.push({
          pathname: `/inventory`,
        });
        // window?.location?.href = "/inventory"
        toast.success(NFT_TRANSACTION_SUCCESS_MSG);
      } else {
        toast.error(NFT_TRANSACTION_ERROR_MSG);
      }
    } catch (error) {
      toast.warning(NFT_TRANSACTION_ERROR_MSG);
      setWebFnLoading(false);
      console.log(error.message);
    }
  };

  // List Button
  const handleList = async () => {
    setWebFnLoading(true);
    const genDoc = generations.filter((gen) => {
      return Number(gen.genId) === Number(item.genId);
    });
    try {
      const res = await listStackNFT(
        web3Instance,
        item.genId,
        genDoc[0].genAddress,
        item.tokenId,
        listingPrice
      );
      setWebFnLoading(false);
      if (res) {
        // window?.location?.href = "/inventory"
        history.push({
          pathname: `/inventory`,
        });
        toast.success(NFT_TRANSACTION_SUCCESS_MSG);
      } else {
        toast.error(NFT_TRANSACTION_ERROR_MSG);
      }
    } catch (error) {
      toast.warning(NFT_TRANSACTION_ERROR_MSG);
      setWebFnLoading(false);
      console.log(error.message);
    }
  };

  const updateUserBalance = async () => {
    if (!window.ethereum.selectedAddress) return;
    const balance = await getBalance(
      web3Instance,
      window.ethereum.selectedAddress
    );
    // console.log("updateUserBalance: ", balance);
    setUserBalance(balance);
  };

  useEffect(() => {
    updateUserBalance();
  }, [window.ethereum.selectedAddress]);

  console.log("singleNFTFetchStatus", singleNFTFetchStatus);
  if (singleNFTFetchStatus === "PENDING" || singleNFTFetchStatus === "IDLE") {
    return <Loader show={true} />;
  }

  if (error) {
    return (
      <div className="noResults">
        Error while finding NFT. Please try again later!
      </div>
    );
  }

  const convertToUSD = (strVal) => {
    let bnVal = new BN(strVal);
    bnVal = bnVal.div(new BN(Math.pow(10, 16) + ""));
    bnVal = bnVal.toNumber() / 100;
    return bnVal;
  };

  console.log("item: ", item);
  return (
    <div className="home-container">
      <div className="wh100">
        <Header
          setRetriggerFlow={() => setRetriggerFlow(!retriggerFlow)}
          showStakingButtons={true}
        />
        <div className="pageLayout">
          <div className="single-nft-home-div container">
            <div className="single-nft-container-div col-lg-10 col-md-10 col-sm-10">
              <div className="back-button" onClick={() => history.goBack()}>
                <LeftOutlined
                  style={{
                    fontSize: "16px",
                    color: "rgb(141, 155, 191)",
                    marginRight: "10px",
                  }}
                />
                <span>Go Back</span>
              </div>
              <div className="single-nft-content-div col-lg-10 col-md-10 col-sm-10">
                {/* Go Back Button & Card */}
                <div>
                  {console.log(item)}
                  <Card
                    title={`NFT Id - ${ellipseAddress(item.id)}`}
                    width={400}
                    src={`https://${item?.imageURL}`}
                    flow={history.location.state?.flow}
                    allowCopy={history.location.state?.flow !== "inventory"} // Do not show copy-to-clipboard if the flow is "inventory"
                  />
                </div>
                {/* NFT Info */}
                <div className="nft-info-main-div">
                  <div>
                    <div className="nft-info">
                      <span>Generation</span> <span>{item?.genId ?? "-"}</span>
                    </div>
                    <div className="nft-info">
                      <span>Token Number</span>{" "}
                      <span>{item?.tokenId ?? "-"}</span>
                    </div>
                    {/* <div className="nft-info">
                    <span>Next Subscription Pay Date</span>{" "}
                    <span>{nextPayDate ? formatDate(nextPayDate) : "-"}</span>
                  </div> */}
                    {/* <div className="nft-info">
                    <span>Tax Rate</span>{" "}
                    <span>
                      {taxRate
                        ? `${isSubscriptionExpired ? "75" : taxRate}%`
                        : "-"}
                    </span>
                  </div> */}
                    {/* <div className="nft-info">
                    <span>Total Subscription Balance</span>{" "}
                    <span>
                      {totalSubBalance
                        ? `${totalSubBalance.toFixed(4)} | $${(
                            totalSubBalance * stackPrice
                          ).toFixed(2)}`
                        : "-"}
                    </span>
                  </div> */}
                    {/* <div className="nft-info">
                    <span>Total NFT Value</span>{" "}
                    <span>${convertToUSD(totalNftValue) ?? "-"}</span>
                  </div> */}
                  </div>

                  {/* Buttons */}
                  {webFnLoading ? (
                    <div className="nft-button">
                      <Loader show={true} spinner />
                    </div>
                  ) : (
                    <div className="nft-button">
                      <h4 className="price-text">
                        <img className="price-logo" src={poly} alt="logo" />
                        {item && Object.keys(item).length > 0
                          ? convertToMatic(item.price)
                          : "-"}
                        {/* 5.2 */}
                        {/* {item &&
                          Object.keys(item).length > 0 &&
                          //   !isNaN(item.price) &&
                          `${"5.2.5"}` | "-"} */}
                        {/* {console.log(item.price)}
                      {item.price.toFixed(2)} */}
                      </h4>
                      {history.location.state?.flow !== "inventory" && (
                        <ButtonComponent
                          click={handleBuyNow}
                          disabled={
                            // false
                            // userBalance.MATIC < item?.price ||
                            webFnLoading ||
                            item?.owner === window.ethereum.selectedAddress
                          }
                          className="btn btn-primary1 btn-block"
                          name="Buy Stack NFT"
                        />
                      )}
                      {history.location.state?.flow === "inventory" &&
                        item?.isForSale && (
                          <ButtonComponent
                            click={handleDelist}
                            disabled={
                              userBalance.MATIC < item?.price || webFnLoading
                            }
                            className="btn btn-primary1 btn-block"
                            name="Delist Stack NFT"
                          />
                        )}
                      {history.location.state?.flow === "inventory" &&
                        !item?.isForSale && (
                          <div className="d-flex">
                            <input
                              className="mr-2"
                              type="number"
                              placeholder="Listing Price (In Matic)"
                              value={listingPrice}
                              onChange={(e) => {
                                setListingPrice(e.target.value);
                              }}
                            />
                            <ButtonComponent
                              click={handleList}
                              disabled={
                                userBalance.MATIC < item?.price || webFnLoading
                              }
                              className="btn btn-primary1 btn-block"
                              name="List Stack NFT"
                            />
                          </div>
                        )}
                    </div>
                  )}

                  {/* Attributes */}
                  {/* {attributesLoading ? (
                  <div className="single-nft-attr-loading-div">
                    <Loader show={true} spinner />
                  </div>
                ) : (
                  <div className="single-nft-attr-div">
                    {attributes.map((attr, index) => (
                      <div key={index} className="nft-attr-div">
                        <span>{attr.trait_type}</span>
                        <span>{attr.value}</span>
                      </div>
                    ))}
                  </div>
                )} */}
                </div>
              </div>
            </div>

            {/* Properties */}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default SingleNFT;
