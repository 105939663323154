import { useEffect, useState } from "react";
import { GraphQLClient } from "graphql-request";
import BN from "bn.js";
import { genList } from "../Utils/constants";

const useFetchSingleNft = (id, flow) => {
  const [singleNFTFetchStatus, setSingleNFTFetchStatus] =
    // (useState < "IDLE") | "PENDING" | "SUCCESS" | ("Error" > "IDLE");
    useState("IDLE");
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  const fetchNFT = async (genID, nftID, address) => {
    const graphQLClient = new GraphQLClient(
      "https://api.studio.thegraph.com/query/64612/genmarket/version/latest"
    );

    let condition = `tokenId: ${nftID}, generationId: ${genID}`;
    if (address) {
      condition += `, seller: "${address}"`;
    }

    const query = `
      {
      stackListings(where: {${condition}}) {
        seller,
        generationId,
        tokenId
        price
      }
    }`;

    try {
      const fetchedData = await graphQLClient.request(query);
      const nftObj = fetchedData.stackListings[0];

      console.log("calling fetchNft: ", fetchedData);

      return {
        data: {
          id: `${genList[nftObj.generationId].genAddress}_${
            nftObj.generationId
          }_${nftObj.tokenId}`,
          imageURL: "",
          tokenId: nftObj.tokenId,
          price: new BN("" + nftObj.price),
          // .toNumber(),
          genId: nftObj.generationId,
        },
      };
    } catch (error) {
      console.error("Failed to fetch GraphQL data:", error.message);
      return {
        data: null,
      };
    }
  };

  const fetchOwnedNFT = async (genID, nftID) => {
    const graphQLClient = new GraphQLClient(
      "https://api.studio.thegraph.com/query/64612/stack_gen0/version/latest"
    );

    console.log("fetched owned NFT: ");
    const query = `
      {
          tokens(where: {tokenID: ${nftID}, tokenAddress: "${genList[genID].genAddress}"}) {
              id
              tokenAddress
              tokenID
              contentURI,
              owner
          }
        }`;
    try {
      const fetchedData = await graphQLClient.request(query);

      console.log("fetchedData: ", fetchedData);

      const stackListings = fetchedData.tokens;

      let isListed = false;
      let priceVal = new BN("0");
      {
        const marketPlaceListResp = await fetchNFT(
          genID,
          nftID,
          window?.ethereum?.selectedAddress
        );

        console.log("marketPlaceListResp: ", marketPlaceListResp);
        if (
          marketPlaceListResp.data &&
          Object.keys(marketPlaceListResp.data).length
        ) {
          isListed = true;
          priceVal = marketPlaceListResp.data.price;
        }
      }

      const data = stackListings.map((nftObj) => {
        const genObj = genList.find(
          (genObj) =>
            genObj.genAddress.toLowerCase() ===
            nftObj.tokenAddress.toLowerCase()
        );

        console.log("priceVal: ", priceVal, priceVal.toString());
        return {
          imageURL: "",
          owner: nftObj.owner,
          tokenId: nftObj.tokenID,
          tokenAddress: nftObj.tokenAddress,
          price: priceVal,
          genId: genObj.genId,
          isForSale: isListed,
        };

        // genId: genObj?.genId,
      });

      console.log("stackListings: ", data);
      return {
        data: data[0],
      };
    } catch (error) {
      console.error("Failed to fetch GraphQL data:", error.message);
      return {
        data: null,
      };
    }
  };

  useEffect(() => {
    const fetch = async () => {
      let statusMessage = "";
      try {
        setSingleNFTFetchStatus("PENDING");
        const splitTokens = id.split("_");
        const genID = splitTokens[1];
        const tokenID = splitTokens[2];
        console.log("fetchNFT params: ", genID, tokenID);
        const resp = await fetchNFT(genID, tokenID);
        if (!resp.data) throw new Error("Failed to fetch NFT");
        setData(resp.data);

        // console.log("success single nft fetch: ", resp.data);
        statusMessage = "SUCCESS";
        // setSingleNFTFetchStatus("SUCCESS");
      } catch (error) {
        console.log(error.message);
        setError(error.message);
        // setSingleNFTFetchStatus("ERROR");
        statusMessage = "ERROR";
      }

      //   console.log("statusMessage: ", statusMessage);
      setSingleNFTFetchStatus(statusMessage);
    };

    const fetchOwned = async () => {
      let statusMessage = "";
      try {
        setSingleNFTFetchStatus("PENDING");
        const splitTokens = id.split("_");
        const genID = splitTokens[1];
        const tokenID = splitTokens[2];
        console.log("fetchNFT params: ", genID, tokenID);
        const resp = await fetchOwnedNFT(genID, tokenID);
        if (!resp.data) throw new Error("Failed to fetch NFT");
        setData(resp.data);

        // console.log("success single nft fetch: ", resp.data);
        statusMessage = "SUCCESS";
        // setSingleNFTFetchStatus("SUCCESS");
      } catch (error) {
        console.log(error.message);
        setError(error.message);
        // setSingleNFTFetchStatus("ERROR");
        statusMessage = "ERROR";
      }

      //   console.log("statusMessage: ", statusMessage);
      setSingleNFTFetchStatus(statusMessage);
    };

    if (singleNFTFetchStatus !== "IDLE") return;

    console.log("checking flow: ", flow);
    if (flow === "marketplace") fetch();

    if (flow === "inventory") fetchOwned();
  }, [id, window.ethereum.selectedAddress]);
  return { data, singleNFTFetchStatus, error };
};

export default useFetchSingleNft;
