import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
// import { MoralisProvider } from "react-moralis";
// import "./firebase.config";
import { Web3ContextProvider } from "./Context/Web3Context";
import { GenerationContextProvider } from "./Context/GenerationContext";
import { NftFetchingHelperContextProvider } from "./Context/NftFetchingHelperContext";
import { WalletAddressContextProvider } from "./Context/WalletAddressContext";
import { ToastContainer } from "react-toastify";
import MarketPlace from "./Pages/MarketPlace";
import SingleNFT from "./Pages/SingleNFT";
import Home from "./Pages/Home";
import Inventory from "./Pages/Inventory";
// import { MoralisAppId, MoralisServerLink } from "./Utils/constants";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles/buttons.scss";
import "./Styles/newStyles.scss";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Router>
      <Switch>
        {/* <MoralisProvider appId={MoralisAppId} serverUrl={MoralisServerLink}> */}
        <Web3ContextProvider>
          <WalletAddressContextProvider>
            <GenerationContextProvider>
              <NftFetchingHelperContextProvider>
                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                />
                <div className="App">
                  <Route exact path="/" component={Home} />
                  <Route
                    exact
                    path="/marketplace/:path"
                    component={MarketPlace}
                  />
                  <Route exact path="/inventory" component={Inventory} />
                  <Route exact path="/nft/:id" component={SingleNFT} />
                </div>
              </NftFetchingHelperContextProvider>
            </GenerationContextProvider>
          </WalletAddressContextProvider>
        </Web3ContextProvider>
        {/* </MoralisProvider> */}
      </Switch>
    </Router>
  );
}

export default App;
