import { useContext, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
// Components
import { Collapse, Select, InputNumber } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import ButtonComponent from "../Buttons/ButtonComponent";
// Contexts
import { NftFetchingHelperContext } from "../../Context/NftFetchingHelperContext";
// css
import "./newSidebar.scss";

const { Option } = Select;
const { Panel } = Collapse;

const Sidebar = (props) => {
  const history = useHistory();

  const pathArr = history.location.pathname.split("/");
  const path = pathArr[pathArr.length - 1];

  const isPathGenesis = path === "genesis";
  const isPathNodes = path === "nodes";
  const isPathInventory = path === "inventory";

  const [nftFetchingHelper, setNftFetchingHelper] = useContext(
    NftFetchingHelperContext
  );

  const [open, setOpen] = useState(true);
  const [minPrice, setMinPrice] = useState(nftFetchingHelper.priceRange[0]);
  const [maxPrice, setMaxPrice] = useState(nftFetchingHelper.priceRange[1]);

  const handleGenId = (genId) => {
    setNftFetchingHelper({
      ...nftFetchingHelper,
      generation: genId,
      generationChanged: true,
      sortChanged: false,
      seeMore: 0,
    });
    if (nftFetchingHelper.sortBy.includes("gen") && genId !== "all") {
      setNftFetchingHelper({ ...nftFetchingHelper, sortBy: "all" });
    }
  };

  const handlePriceRange = () => {
    setNftFetchingHelper({
      ...nftFetchingHelper,
      priceRange: [minPrice, maxPrice],
      seeMore: 0,
    });
  };

  const resetPriceRange = () => {
    setNftFetchingHelper({
      ...nftFetchingHelper,
      priceRange: [0, null],
      seeMore: 0,
    });
    setMinPrice(0);
    setMaxPrice(null);
  };

  return (
    <div className="sidebar" style={{ width: open ? 360 : 60 }}>
      <div className="sidebarheading">
        <div onClick={() => history.push("/")} className="title-div">
          <LeftOutlined
            style={{
              fontSize: "16px",
              color: "rgb(141, 155, 191)",
              marginRight: "10px",
            }}
          />
          <span>
            {isPathGenesis
              ? "Genesis Nodes"
              : isPathNodes
              ? " All Node NFTs"
              : "My Node NFTs"}
          </span>
        </div>
      </div>
      {open ? (
        <div>
          <Collapse defaultActiveKey={["1", "2"]}>
            <Panel header="Price (in Matic)" key="1">
              <div className="price-range-div">
                <InputNumber
                  size="large"
                  placeholder="Min"
                  value={minPrice}
                  onChange={(value) => {
                    setMinPrice(value);
                  }}
                />
                to
                <InputNumber
                  size="large"
                  placeholder="Max"
                  value={maxPrice}
                  onChange={(value) => {
                    setMaxPrice(value);
                  }}
                />
              </div>
              <ButtonComponent
                click={handlePriceRange}
                className="applyBtn"
                name="Apply"
              />
              <ButtonComponent
                click={resetPriceRange}
                className="resetBtn"
                name="Reset"
              />
            </Panel>
            {props.generations?.length && (
              <Panel header="Generation" key="2">
                <Select
                  size="large"
                  onChange={(value) => handleGenId(value)}
                  style={{ width: "100%" }}
                  value={nftFetchingHelper.generation}
                >
                  <Option value="all">All</Option>
                  {props.generations?.map((generation) => (
                    <Option
                      key={generation.genId}
                      value={Number(generation.genId)}
                    >
                      Gen {generation.genId}
                    </Option>
                  ))}
                </Select>
              </Panel>
            )}
          </Collapse>
        </div>
      ) : null}
    </div>
  );
};

export default withRouter(Sidebar);
