import {
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  doc,
  onSnapshot,
  getDocs,
} from "firebase/firestore";
// import { db } from "../firebase.config";
import {
  gasPrice,
  marketAddress,
  QUERY_LIMIT,
  stackTokenAddress,
} from "./constants";
import stackTokenContractAbi from "../abis/stack.json";
import uniswapv2 from "../abis/uniswapv2.json";
import BN from "bn.js";

// Store last fetched nfts
let lastFetchedNft = null;

// export const fetchCollection = async (
//   collectionName,
//   whereOptions = [],
//   sortOptions = [],
//   seeMore
// ) => {
//   try {
//     const db = {};
//     const dataRef = collection(db, collectionName);
//     let q;
//     if (seeMore) {
//       if (lastFetchedNft !== null) {
//         q = query(
//           dataRef,
//           ...whereOptions.map((filter) =>
//             where(filter.fieldPath, filter.operatorString, filter.value)
//           ),
//           ...sortOptions.map((sort) =>
//             orderBy(sort.sortOption, sort.sortingType)
//           ),
//           startAfter(lastFetchedNft),
//           limit(QUERY_LIMIT)
//         );
//       } else {
//         q = query(
//           dataRef,
//           ...whereOptions.map((filter) =>
//             where(filter.fieldPath, filter.operatorString, filter.value)
//           ),
//           ...sortOptions.map((sort) =>
//             orderBy(sort.sortOption, sort.sortingType)
//           ),
//           limit(QUERY_LIMIT)
//         );
//       }
//     } else {
//       q = query(
//         dataRef,
//         ...whereOptions.map((filter) =>
//           where(filter.fieldPath, filter.operatorString, filter.value)
//         ),
//         ...sortOptions.map((sort) =>
//           orderBy(sort.sortOption, sort.sortingType)
//         ),
//         limit(QUERY_LIMIT)
//       );
//     }
//     const querySnap = await getDocs(q);

//     // Store last fetched NFT
//     lastFetchedNft = querySnap.docs[querySnap.docs.length - 1];

//     return querySnap;
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const fetchDocument = async (collectionName, setData, id) => {
//   try {
//     const docRef = doc(db, collectionName, id);
//     // onSnapshot method will listen to changes and will fetch new changes automatically
//     onSnapshot(docRef, (doc) => {
//       setData({ ...doc.data(), id: doc.id });
//     });
//   } catch (error) {
//     console.log(error);
//   }
// };

export const setupNetwork = async (chainId) => {
  const provider = window.ethereum;
  if (provider) {
    switch (chainId) {
      case 137:
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${chainId.toString(16)}`,
                chainName: "Polygon",
                nativeCurrency: {
                  name: "MATIC",
                  symbol: "MATIC",
                  decimals: 18,
                },
                rpcUrls: ["https://polygon-rpc.com"],
                blockExplorerUrls: ["https://polygonscan.com/"],
              },
            ],
          });
          return true;
        } catch (error) {
          console.error(error);
          return false;
        }
      default:
        return;
    }
  } else {
    console.error(
      "Can't setup the desired network on metamask because window.ethereum is undefined"
    );
    return false;
  }
};

export const networkCheckFn = async (web3Instance) => {
  const response = web3Instance.eth.net.getId().then((id) => {
    if (Number(id) === Number(window._env_.NETWORK)) {
      return true;
    } else {
      return false;
    }
  });
  return await response;
};

export function toFixed(x) {
  var e = 0;
  if (Math.abs(x) < 1.0) {
    e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
}

export const getLastGenAddress = (data) => {
  return Array.isArray(data) && data.length > 0
    ? data.sort((a, b) => (a.genId > b.genId ? 1 : -1))[data.length - 1]
    : { genId: null, genAddress: null };
};

export const getStackTokenPrice = async (Moralis) => {
  const options = {
    address: "0x6855f7bb6287F94ddcC8915E37e73a3c9fEe5CF3",
    chain: "bsc",
    exchange: "PancakeSwapv2",
  };
  const price = await Moralis.Web3API.token.getTokenPrice(options);
  return price.usdPrice;
};

// export const getMaticTokenPrice = async (web3Instance, inputVal) => {
//   // const options = {
//   //   address: "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
//   //   chain: "bsc",
//   //   exchange: "PancakeSwapv2",
//   // };

//   console.log("inputVal: ", inputVal);
//   if (!inputVal || inputVal === "0") return "0";

//   const oneEth = "1000000000000000000";
//   const marketContract = new web3Instance.eth.Contract(
//     uniswapv2,
//     "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff"
//   );
//   console.log("before calling get amounts out");
//   const response = await marketContract.methods
//     .getAmountsOut(inputVal, [
//       "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
//       "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
//     ])
//     .call();

//   console.log("getMaticTokenPrice: ", response);
//   // const price = await Moralis.Web3API.token.getTokenPrice(options);
//   // return price.usdPrice;
//   //   return new BN(response[1]).div(new BN(response[0])).toString();
//   return new BN(response[1]).mul(new BN(Math.pow(10, 12) + "")).toString();
// };

// export const getStackTokenPrice = async (web3Instance, inputVal) => {
//   // const options = {
//   //   address: "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
//   //   chain: "bsc",
//   //   exchange: "PancakeSwapv2",
//   // };

//   if (!inputVal || inputVal === "0") return "0";

//   //   const oneEth = "1000000000000000000";
//   const marketContract = new web3Instance.eth.Contract(
//     uniswapv2,
//     "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff"
//   );
//   const response = await marketContract.methods
//     .getAmountsOut(inputVal, [
//       "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
//       "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
//     ])
//     .call();

//   //   // const price = await Moralis.Web3API.token.getTokenPrice(options);
//   //   // return price.usdPrice;
//   return new BN(response[1]).mul(new BN(Math.pow(10, 12) + "")).toString();

//   //   return "2097244962389427";
// };

export const getMaticTokenPrice = async (Moralis) => {
  const options = {
    address: "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
    chain: "bsc",
    exchange: "PancakeSwapv2",
  };
  const price = await Moralis.Web3API.token.getTokenPrice(options);
  return price.usdPrice;
};

export const getBalance = async (web3Instance, ethAddress) => {
  try {
    const StackTokenContract = new web3Instance.eth.Contract(
      stackTokenContractAbi,
      stackTokenAddress
    );

    let balanceObj = {};
    console.log("selected address: ", ethAddress, web3Instance);
    const balanceAcc = await web3Instance.eth.getBalance(
      ethAddress,
      (err, balance) => {
        return balance;
      }
    );
    balanceObj.MATIC = Number(balanceAcc);
    const stackBalance = await StackTokenContract.methods
      .balanceOf(ethAddress)
      .call();
    balanceObj.STACK = Number(stackBalance);
    return balanceObj;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const sortGens = (gens) => {
  return gens?.sort((a, b) => Number(a.genId) - Number(b.genId));
};

export const formatDate = (date) => {
  if (!date) return new Date(0).toLocaleDateString();
  return new Date(date * 1000).toLocaleDateString();
};

export const convertToMatic = (bnVal, vcheck) => {
  // let bnVal = new BN(strVal);
  let beforeVal = bnVal.toString();
  bnVal = bnVal.div(new BN(Math.pow(10, 16) + ""));
  bnVal = bnVal.toNumber() / 100;
  //   bnVal += 1;
  //   bnVal = bnVal.mul(new BN("2")).toString();

  console.log("bnVal: ", bnVal, vcheck, beforeVal);
  return bnVal;
  //   return "1234";
};

export const sortNfts = (nfts, sortBy) => {
  if (!nfts) return;

  switch (sortBy) {
    case "all":
      return nfts;

    case "price-low":
      return nfts.sort((a, b) => a.price - b.price);

    case "price-high":
      return nfts.sort((a, b) => b.price - a.price);

    case "token-low":
      return nfts.sort((a, b) => a.tokenId - b.tokenId);

    case "token-high":
      return nfts.sort((a, b) => b.tokenId - a.tokenId);

    case "gen-low":
      return nfts.sort((a, b) => a.genId - b.genId);

    case "gen-high":
      return nfts.sort((a, b) => b.genId - a.genId);

    default:
      break;
  }
};

export default function ellipseAddress(address = "", width = 5) {
  console.log("ellipse address: ", address);
  if (!address) {
    return "";
  }
  return `${address.slice(0, width)}...${address.slice(-width)}`;
}

export const getAttributes = async (genId, tokenId) => {
  try {
    const response = await fetch(`https://nft.stackos.io/${genId}/${tokenId}`, {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
        "Access-Control-Allow-Origin": "*",
      },
    });
    console.log("response: ", response);
    const bodyText = await response.body.getReader();
    const { value } = await bodyText.read();
    const responseString = new TextDecoder().decode(value);
    const responseObj = JSON.parse(responseString);
    return responseObj?.attributes ?? [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const checkApproval = async (
  web3Instance,
  currencyAddress,
  contractAbi,
  price
) => {
  let tokenContract = new web3Instance.eth.Contract(
    contractAbi,
    currencyAddress
  );
  const currentAllowance = await tokenContract.methods
    .allowance(window.ethereum.selectedAddress, marketAddress)
    .call();
  let approveResponse = {};
  const amount = web3Instance.utils.toBN(
    web3Instance.utils.toWei(price.toString())
  );

  if (currentAllowance < amount) {
    const transaction = tokenContract.methods.approve(marketAddress, amount);
    const options = {
      to: currencyAddress,
      from: window.ethereum.selectedAddress,
      data: transaction.encodeABI(),
      gasPrice: gasPrice,
    };
    approveResponse = await web3Instance.eth.sendTransaction(options);
  } else {
    approveResponse.status = true;
  }

  return approveResponse.status;
};

export const checkSubscriptionExpired = (date) => {
  // Add 5 days extension to date
  const expirationDate = new Date(date).getUTCDate() + 5;

  const currentDate = new Date(Date.now()).getUTCDate();

  return currentDate < expirationDate;
};
