import { gasPrice, marketAddress, GAS_HIKE } from "../constants";
import marketContractAbi from "../../abis/market.json";
import stackosNFTContractAbi from "../../abis/stackosNFTBasic.json";

export const listStackNFT = async (
  web3Instance,
  generationId,
  genAddress,
  tokenId,
  price
) => {
  try {
    const marketContract = new web3Instance.eth.Contract(
      marketContractAbi,
      marketAddress
    );
    const nftPrice = web3Instance.utils.toBN(
      web3Instance.utils.toWei(price.toString())
    );
    const genContract = new web3Instance.eth.Contract(
      stackosNFTContractAbi,
      genAddress
    );
    let gasPrice = await web3Instance.eth.getGasPrice();

    let increasedGasPrice = gasPrice * GAS_HIKE;
    increasedGasPrice = increasedGasPrice.toFixed(0);
    //   1000000000
    // 840297527750
    //
    // console.log("gasPrice: ", gasPrice, increasedGasPrice, gasLimit);

    const approvalAddress = await genContract.methods
      .getApproved(tokenId)
      .call();

    if (approvalAddress !== marketAddress) {
      const approval = await genContract.methods
        .approve(marketAddress, tokenId)
        .send({
          from: window.ethereum.selectedAddress,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
          gasPrice: increasedGasPrice,
        });

      if (!approval.status) {
        throw new Error("Approval failed!");
      }
    }

    const response = await marketContract.methods
      .listStackNFT(generationId, tokenId, nftPrice)
      .send({
        from: window.ethereum.selectedAddress,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        gasPrice: increasedGasPrice,
      });
    return response.status;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const deListStackNFT = async (web3Instance, generationId, tokenId) => {
  try {
    const marketContract = new web3Instance.eth.Contract(
      marketContractAbi,
      marketAddress
    );

    let gasPrice = await web3Instance.eth.getGasPrice();

    // 1453263547220
    // 1000000000

    let increasedGasPrice = gasPrice * GAS_HIKE;
    increasedGasPrice = increasedGasPrice.toFixed(0);

    console.log("delist stack: ", generationId, tokenId, increasedGasPrice);
    const response = await marketContract.methods
      .deListStackNFT(generationId, tokenId)
      .send({
        from: window.ethereum.selectedAddress,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        gasPrice: increasedGasPrice,
      });
    return response;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const buyStack = async (web3Instance, generationId, tokenId, price) => {
  try {
    const marketContract = new web3Instance.eth.Contract(
      marketContractAbi,
      marketAddress
    );

    let gasPrice = await web3Instance.eth.getGasPrice();

    let increasedGasPrice = gasPrice * GAS_HIKE;
    increasedGasPrice = increasedGasPrice.toFixed(0);

    const nftPrice = web3Instance.utils.toBN(
      web3Instance.utils.toWei(price.toString())
    );
    console.log(
      "nftPrice: ",
      increasedGasPrice.toString(),
      price.toString(),
      generationId,
      tokenId,
      window.ethereum.selectedAddress
    );
    const response = await marketContract.methods
      .buyStack(generationId, tokenId)
      .send({
        from: window.ethereum.selectedAddress,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        gasPrice: increasedGasPrice,
        value: price.toString(),
      });
    console.log("buyStack: ", response);
    return response;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const stackToLot = async (web3Instance, generationId, tokenId) => {
  try {
    const marketContract = new web3Instance.eth.Contract(
      marketContractAbi,
      marketAddress
    );
    const response = await marketContract.methods
      .stackToLot(generationId, tokenId)
      .call();
    return response;
  } catch (e) {
    console.log(e);
    return false;
  }
};
