import { useEffect, useState } from "react";
// import { fetchCollection } from "../Utils/utils";

export const useFetchGenerations = () => {
  const [generations, setGenerations] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchGenerations = async (offset) => {
    // const graphQLClient = new GraphQLClient(
    //   "https://api.studio.thegraph.com/query/64612/genmarket/version/latest"
    // );

    //     const query = `
    //   {
    //       stackListings(first: ${NFT_QUERY_LIMIT}, skip: ${offset}) {
    //         seller,
    //         generationId,
    //         tokenId
    //         price
    //       }
    //     }`;
    //     try {
    //       const fetchedData = await graphQLClient.request(query);

    //       const stackListings = fetchedData.stackListings;

    //       return {
    //         data: stackListings.map((nftObj) => ({
    //           imageURL: "",
    //           tokenId: nftObj.tokenId,
    //           price: new BN(nftObj.price)
    //             .div(new BN(Math.pow(10, 18)))
    //             .toString(),
    //           genId: nftObj.generationId,
    //         })),
    //       };
    //     } catch (error) {
    //       console.error("Failed to fetch GraphQL data:", error.message);
    //       return {
    //         data: null,
    //       };
    //     }

    return [
      {
        genAddress: "0x80a44A37B9198F5fa5C2F4EF2748cF109A288174",
        genId: 0,
      },
      {
        genAddress: "0xBF55265e7C2Cb77D64c791430C759069620D5A21",
        genId: 1,
      },
      {
        genAddress: "0xb65E58D28f2C2bC8ffe6DF20141301C8c87e792C",
        genId: 2,
      },
      {
        genAddress: "0x244cB430d58A197BE31919336406695bAb58d9F3",
        genId: 3,
      },
      {
        genAddress: "0x3C7196C7BE3b54c4644e8D6562EcbD4F5C0Fd7EC",
        genId: 4,
      },
      {
        genAddress: "0x48199892Ee19A045B2310C0AB39c39de55BF76FD",
        genId: 5,
      },
      {
        genAddress: "0xcc1879ebd66bD2a2937a1602F29645843d5d66DE",
        genId: 6,
      },
      {
        genAddress: "0x8FbeeE065D7F626F771a7dd2120CF831639E469f",
        genId: 7,
      },
      {
        genAddress: "0x62615ace553F6bB73fa6336cFEDc0B57C49D5642",
        genId: 8,
      },
      {
        genAddress: "0x55bfB8de8820762Ad9911467edA615a89f561860",
        genId: 9,
      },
      {
        genAddress: "0x514c90500b4e87Ce77524EF161d442Eb937EA9Ef",
        genId: 10,
      },
    ];
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        // https://api.studio.thegraph.com/query/64612/genfetch/version/latest

        // const querySnap = await fetchCollection("generation");
        // const generations = [];
        // querySnap.forEach((doc) => {
        //   generations.push(doc.data());
        // });
        const generations = await fetchGenerations(0);
        // const generations = [];
        setGenerations(generations);
        setLoading(false);
      } catch (error) {
        setError(error);
      }
    };
    fetch();
  }, []);
  return { generations, loading, error };
};
