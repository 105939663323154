import { useContext, useState } from "react";
// Components
import Header from "../Components/Header/Header";
import Card from "../Components/Card/Card";
// Assets
import stackVideo from "../Assets/stackVideo.gif";
import { Footer } from "../Components/Footer/Footer";
import { NftFetchingHelperContext } from "../Context/NftFetchingHelperContext";
import { NFT_QUERY_LIMIT } from "../Utils/constants";

const Home = (props) => {
  const [retriggerFlow, setRetriggerFlow] = useState(false);

  const [, setNftFetchingHelper] = useContext(NftFetchingHelperContext);

  const onCardClick = (path) => {
    const generationValue = path === "genesis" ? 0 : "all";
    setNftFetchingHelper({
      curOffset: 0,
      nextOffset: NFT_QUERY_LIMIT,
      generation: generationValue,
      generationChanged: true,
      sortBy: "all",
      sortChanged: false,
      seeMore: 0,
      priceRange: [0, null],
    });
    props.history.push(`/marketplace/${path}`);
  };

  return (
    <div className="home-container">
      <div className="wh100">
        <Header
          setRetriggerFlow={() => setRetriggerFlow(!retriggerFlow)}
          showStakingButtons={true}
        />
        <div className="container">
          <div className="home-div">
            <Card
              title="Genesis Nodes"
              width={350}
              onClick={() => onCardClick("genesis")}
              src={stackVideo}
            />
            <Card
              title="All Node NFTs"
              width={350}
              onClick={() => onCardClick("nodes")}
              src={stackVideo}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
