import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// import { useMoralis } from "react-moralis";
// Contexts
import { NftFetchingHelperContext } from "../../Context/NftFetchingHelperContext";
// Components
import Card from "../Card/Card";
import Loader from "../Loader/loader";
// Utils
import { NFT_QUERY_LIMIT, QUERY_LIMIT } from "../../Utils/constants";
import { getMaticTokenPrice } from "../../Utils/utils";
// Assets
import stackVideo from "../../Assets/stackVideo.gif";

export const InfiniteList = ({
  flow,
  data,
  count,
  loading,
  error,
  generations,
}) => {
  const history = useHistory();

  const [nftFetchingHelper, setNftFetchingHelper] = useContext(
    NftFetchingHelperContext
  );

  //   const { Moralis } = useMoralis();
  const [maticPrice, setMaticPrice] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Get Matic Price
      try {
        // const maticPrice = await getMaticTokenPrice(Moralis);
        const maticPrice = "0";
        setMaticPrice(maticPrice);
      } catch (err) {
        console.log("error", err);
      }
    };
    fetchData();
  }, []);

  const handleSeeMore = () => {
    setNftFetchingHelper({
      ...nftFetchingHelper,
      seeMore: (n) => n + 1,
      nextOffset: nftFetchingHelper.nextOffset + NFT_QUERY_LIMIT,
      curOffset: nftFetchingHelper.nextOffset,
      generationChanged: false,
      sortChanged: false,
    });
  };

  window.onscroll = () => {
    const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
    const distanceFromBottom = scrollHeight - scrollTop - clientHeight;
    if (distanceFromBottom <= 0) {
      count >= QUERY_LIMIT && handleSeeMore();
    }
  };

  const onCardClick = (item) => {
    console.log("item click: ", item);
    history.push({
      pathname: `/nft/${generations[item.genId].genAddress}_${item.genId}_${
        item.tokenId
      }`,
      state: {
        flow: `${flow === "inventory" ? "inventory" : "marketplace"}`,
      },
    });
  };

  if (loading && nftFetchingHelper.sortChanged) return <Loader show={true} />;
  if (loading && nftFetchingHelper.generationChanged)
    return <Loader show={true} />;
  if (error)
    return (
      <div className="noResults">
        Error while finding NFTs. Please try again later!
      </div>
    );
  if (!data?.length)
    return <div className="noResults">Sorry we couldn’t find any results</div>;

  return (
    <div className="normal-list-container">
      <div className="nft-container">
        {data?.map((token, index) => (
          <Card
            key={index}
            onClick={() => onCardClick(token)}
            width={225}
            nftItem
            showBuyNow
            src={stackVideo}
            item={token}
            subTitle={`Generation - ${token?.genId ?? "-"}`}
            flow={flow}
            maticPrice={"1"}
          />
        ))}
      </div>
      {loading && (
        <div className="my-5">
          <Loader show={true} spinner />
        </div>
      )}
    </div>
  );
};
